import { router } from "@inertiajs/react";
import React from "react";

import PasswordIcon from "../../../../public/passwordIcon.svg";
import SimpleButton from "../../Buttons/SimpleButton";
import Typography from "../../Typography";
import MessageIcon from "./messageIcon.svg";
import { Emoti, FakeButton, FaqCard, FirstPart, TextDiv } from "./styles";
import { FaqComponentProps } from "./types";

const FaqCardComponent = ({
  variant = "questionSection",
  onClick,
  buttonLabel,
}: FaqComponentProps) => {
  const onPressButton = () => {
    if (variant == "questionSection") {
      router.get("/faq");
    } else {
      if (onClick) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick();
      }
    }
  };
  return (
    <FaqCard variant={variant}>
      <FirstPart>
        <Emoti variant={variant}>
          {variant == "dataSection" ? (
            <img src={PasswordIcon} alt="password-icon" />
          ) : (
            <img src={MessageIcon} alt="balões de texto" />
          )}
        </Emoti>
        <TextDiv>
          {variant == "questionSection" ? (
            <Typography variant="h4" color="black">
              Precisando de ajuda?
              <Typography variant="h4" color="grey300">
                &nbsp; Veja nossa seção de perguntas frequentes.
              </Typography>
            </Typography>
          ) : variant == "email" ? (
            <Typography variant="h4" color="white">
              Precisa falar com a gente? Mande um e-mail para
              <Typography variant="h4" color="secondary300">
                &nbsp; contato@geedee.com.br
              </Typography>
            </Typography>
          ) : (
            <Typography variant="h4" color="black">
              Precisar mudar a senha de acesso?
            </Typography>
          )}
        </TextDiv>
      </FirstPart>
      {variant != "email" ? (
        <FakeButton>
          <SimpleButton
            label={buttonLabel ? buttonLabel : "Acessar Página"}
            variant="simple"
            onClick={onPressButton}
          />
        </FakeButton>
      ) : (
        <></>
      )}
    </FaqCard>
  );
};

export default FaqCardComponent;
