import React from "react";
import { FiArrowUpRight } from "react-icons/fi";

import CirclesSmallComponent from "@/components/Circles/CirclesSmallComponent";
import Typography from "@/components/Typography";
import { dateFormat } from "@/utils/dateFormater";

import { Button, ButtonResponsive, Containter } from "./styles";
import { BillsToPayProps } from "./types";

const BillsToPay = ({ variant, date, onClick }: BillsToPayProps) => {
  return (
    <Containter variant={variant}>
      <div className="infos">
        <div className="text">
          <Typography variant="h3" color="black">
            {variant == "pay" ? (
              "Pagar conta agora :)"
            ) : variant == "in-day" ? (
              <>Parabéns! Suas contas Geedee estão em dia :)</>
            ) : (
              <>Você possui contas Geedee a pagar.</>
            )}
          </Typography>
        </div>
        <Typography variant="h6" color={variant == "pay" ? "secondary500" : "black"}>
          {variant != "in-day" ? `Vencimento ${dateFormat(date)}` : null}
        </Typography>
      </div>
      <div className="circles-position">
        <CirclesSmallComponent
          variant={variant == "in-day" ? "grey" : variant == "late" ? "red" : "green"}
        />
        {variant != "in-day" && (
          <Button>
            <div className="button-position cursor-pointer" onClick={onClick}>
              <Typography variant="pLarge" color="white">
                {variant == "pay" ? "Pagar conta" : "Checar contas"}
              </Typography>
              <div className="icon">
                <FiArrowUpRight />
              </div>
            </div>
          </Button>
        )}
      </div>
      <ButtonResponsive>
        <div className="circles-responsive">
          <CirclesSmallComponent
            variant={variant == "in-day" ? "grey" : variant == "late" ? "red" : "green"}
          />
        </div>
        <div className="button-position cursor-pointer" onClick={onClick}>
          <Typography variant="pLarge" color="white">
            {variant == "pay" ? "Pagar conta" : "Checar contas"}
          </Typography>
          <div className="icon">
            <FiArrowUpRight />
          </div>
        </div>
      </ButtonResponsive>
    </Containter>
  );
};

export default BillsToPay;
